import appInstance from '../../App';

const body = document.querySelector('body');
const siteNavigation = <HTMLElement>body?.querySelector('.site-navigation');
const burgerMenuButton = <HTMLElement>siteNavigation?.querySelector('.hamburger');
const siteLogo = <HTMLElement>siteNavigation?.querySelector('.site-navigation__logo');
const mainNavigation = <HTMLElement>siteNavigation?.querySelector('.site-navigation__navigation');
const toolbar = <HTMLElement>siteNavigation?.querySelector('.site-navigation__toolbar');
const toolbarSubNavigation = <HTMLElement>siteNavigation?.querySelector('.toolbar-sub-navigation');
const toolbarSubNavigationPlaceholder = <HTMLElement>siteNavigation?.querySelector('.toolbar-sub-navigation__group--other div');

const burgerMenuButtonActiveClass = '--is-active';
let setHabitate: boolean = false;

/**
 * Toggle the active states of the mobile navigation
 * @param mobileNavigation
 */
const toggleActive = (mobileNavigation: HTMLDivElement) => {
  burgerMenuButton.classList.toggle(
    `hamburger${burgerMenuButtonActiveClass}`,
  );

  if (mobileNavigation) {
    mobileNavigation.classList.toggle(
      `mobile-navigation${burgerMenuButtonActiveClass}`,
    );
  }

  if (body) {
    body.classList.toggle('locked');
  }
};

/**
 * Generates the mobile navigation by cloning the main
 * navigation elements and appending them into a new div element.
 * @returns HTMLDivElement
 */
const generateMobileNavigation = () => {
  const navigation: HTMLDivElement = document.createElement('div');
  const navigationInner: HTMLDivElement = document.createElement('div');
  navigation.className = 'mobile-navigation';
  navigationInner.className = 'mobile-navigation__inner container';

  if (siteLogo) {
    navigationInner.appendChild(
      siteLogo.cloneNode(true),
    );
  }

  if (mainNavigation) {
    navigationInner.appendChild(
      mainNavigation.cloneNode(true),
    );
  }

  if (toolbar) {
    navigationInner.appendChild(
      toolbar.cloneNode(true),
    );
  }

  navigation.appendChild(navigationInner);

  return <HTMLDivElement>navigation;
};

const threeTierNavigation = () => {
  toolbarSubNavigation?.querySelectorAll('.js-hover-link').forEach((link, index) => {
    const subNav = link.querySelector('.toolbar-sub-navigation__third-tier');
    const placeholder = toolbarSubNavigationPlaceholder;

    link.setAttribute('data-nav-id', `nav-${index}`);
    subNav?.setAttribute('id', `nav-${index}`);

    if (subNav) {
      placeholder.appendChild(subNav);
    }

    link.addEventListener('mouseover', () => {
      const currentLink = toolbarSubNavigation.querySelector('.is-active');
      const currentNav = placeholder.querySelector('.is-visible');

      if (currentNav) {
        currentNav.classList.remove('is-visible');
      }

      if (currentLink) {
        currentLink.classList.remove('is-active');
      }

      link.classList.add('is-active');
      placeholder.querySelector(`#nav-${index}`)?.classList.add('is-visible');
    });
  });
};

/**
 * Initialises the mobile navigation
 */
const Navigation = () => {
  const mobileNavigation: HTMLDivElement = generateMobileNavigation();

  if (mobileNavigation) {
    body?.appendChild(mobileNavigation);
  }

  burgerMenuButton?.addEventListener('click', (event: Event) => {
    event.preventDefault();
    toggleActive(mobileNavigation);
    if (!setHabitate) {
      // Something about this is not right and keeps breaking
      // something in the markup.
      appInstance.update(mobileNavigation);
      setHabitate = true;
    }
  });

  threeTierNavigation();
};

export default Navigation;
