import Navigation from './non-react-ui/header/navigation';
import './styles/main.scss';
import './spritemap';
import './App';

if (navigator.appVersion.indexOf('Win') !== -1) {
  document.querySelector('body')?.classList.add('win');
}

Navigation();
