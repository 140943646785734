import * as ReactHabitat from 'react-habitat';

// Define globals
declare global {
  interface Window {
    updateHabitat?: (Node?: any) => void;
    SpeechRecognition?: any;
    webkitSpeechRecognition?: any;
    isMobile: boolean;
  }
}

class App extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    // Create a habitat container builder
    const containerBuilder: ReactHabitat.IContainerBuilder = new ReactHabitat.ContainerBuilder();
    // Register our components that we want to expose to the DOM
    // NOTE: We will want these to pull in async at somepoint to
    // avoid loading in more then nessesary
    containerBuilder.registerAsync(() => import('./components/common/Image')).as('Image');
    containerBuilder.registerAsync(() => import('./components/common/ImageObject')).as('ImageObject');
    containerBuilder.registerAsync(() => import('./components/common/DateCategory')).as('DateCategory');
    containerBuilder.registerAsync(() => import('./components/InlineGallery')).as('InlineGallery');
    containerBuilder.registerAsync(() => import('./components/Gallery')).as('Gallery');
    containerBuilder.registerAsync(() => import('./components/VideoObject')).as('VideoObject');
    containerBuilder.registerAsync(() => import('./components/common/Quote')).as('Quote');
    containerBuilder.registerAsync(() => import('./components/CookieBanner')).as('CookieBanner');
    containerBuilder.registerAsync(() => import('./components/common/SharePane')).as('SharePane');
    containerBuilder.registerAsync(() => import('./components/common/SocialLinks')).as('SocialLinks');
    containerBuilder.registerAsync(() => import('./components/common/TagList')).as('TagList');
    containerBuilder.registerAsync(() => import('./components/PageHeader')).as('Header');
    containerBuilder.registerAsync(() => import('./components/ImageBlock')).as('ImageBlock');
    containerBuilder.registerAsync(() => import('./components/SideBySidePromo')).as('SideBySidePromo');
    containerBuilder.registerAsync(() => import('./components/ShortWidePromo')).as('ShortWidePromo');
    containerBuilder.registerAsync(() => import('./components/ContentBlock')).as('ContentBlock');
    containerBuilder.registerAsync(() => import('./components/Faqs')).as('Faqs');
    containerBuilder.registerAsync(() => import('./components/HeroSlider')).as('HeroSlider');
    containerBuilder.registerAsync(() => import('./components/LanguageSelect')).as('LanguageSelect');
    containerBuilder.registerAsync(() => import('./components/RelatedContent')).as('RelatedContent');
    containerBuilder.registerAsync(() => import('./components/Sponsors')).as('Sponsors');
    containerBuilder.registerAsync(() => import('./components/GalleryImage')).as('GalleryImage');
    containerBuilder.registerAsync(() => import('./components/ContentHero')).as('ContentHero');
    containerBuilder.registerAsync(() => import('./components/Explainer')).as('Explainer');
    containerBuilder.registerAsync(() => import('./components/StatsBlock')).as('StatsBlock');
    containerBuilder.registerAsync(() => import('./components/DisciplinesListing')).as('DisciplinesListing');
    containerBuilder.registerAsync(() => import('./components/ResultsAccordion')).as('ResultsAccordion');
    containerBuilder.registerAsync(() => import('./components/GatewayCarousel')).as('GatewayCarousel');
    containerBuilder.registerAsync(() => import('./components/LiveComponent')).as('LiveComponent');
    containerBuilder.registerAsync(() => import('./components/LivelyEmbedModule')).as('LivelyEmbedModule');
    containerBuilder.registerAsync(() => import('./components/ContentWithSidebarBlock')).as('ContentWithSidebarBlock');
    containerBuilder.registerAsync(() => import('./components/CalendarSchedule')).as('CalendarSchedule');
    containerBuilder.registerAsync(() => import('./components/RidersList')).as('RidersList');
    containerBuilder.registerAsync(() => import('./components/RiderTeamCard')).as('RiderTeamCard');
    containerBuilder.registerAsync(() => import('./components/RegulationsDownload')).as('RegulationsDownload');
    containerBuilder.registerAsync(() => import('./components/Watch')).as('Watch');
    containerBuilder.registerAsync(() => import('./components/SearchOverlay')).as('SearchOverlay');
    containerBuilder.registerAsync(() => import('./components/IframeWrapper')).as('IframeWrapper');
    containerBuilder.registerAsync(() => import('./components/JavaScriptEmbedModule')).as('JavaScriptEmbedModule');
    containerBuilder.registerAsync(() => import('./components/ProfileBlock')).as('ProfileBlock');
    containerBuilder.registerAsync(() => import('./components/CountdownModule')).as('CountdownModule');
    containerBuilder.registerAsync(() => import('./components/Typeform')).as('Typeform');
    containerBuilder.registerAsync(() => import('./components/RankingWidget')).as('RankingWidget');
    containerBuilder.registerAsync(() => import('./components/TechnicalRankingModule')).as('TechnicalRankingModule');
    containerBuilder.registerAsync(() => import('./components/LatestNewsCarousel')).as('LatestNewsCarousel');
    containerBuilder.registerAsync(() => import('./components/EventHeader')).as('LiveEventHeader');

    // Modules
    containerBuilder.registerAsync(() => import('./modules/NewsListings')).as('NewsListingsModule');
    containerBuilder.registerAsync(() => import('./modules/Calendar')).as('CalendarModule');
    containerBuilder.registerAsync(() => import('./modules/Hubs')).as('HubsModule');
    containerBuilder.registerAsync(() => import('./modules/CompetitionDetails')).as('CompetitionDetailsModule');
    containerBuilder.registerAsync(() => import('./modules/Discipline')).as('DisciplineModule');
    containerBuilder.registerAsync(() => import('./modules/Riders')).as('RidersModule');
    containerBuilder.registerAsync(() => import('./modules/TeamDetails')).as('TeamDetailsModule');
    containerBuilder.registerAsync(() => import('./modules/Home')).as('HomeModule');
    containerBuilder.registerAsync(() => import('./modules/RiderDetails')).as('RiderDetailsModule');

    // Set the DOM container
    this.setContainer(containerBuilder.build());
  }

  updateHabitat() {
    this.update();
  }
}

// Create a instanse of a react habitat app
const appInstance = new App();
// this will allow habitat to scan the dom for references to components
window.updateHabitat = appInstance.update.bind(appInstance);

export default appInstance;
